import $api from "@/api";
import { db, parentRef } from "@/firebase";
import { set, remove, ref } from "@firebase/database";
import { defineStore, storeToRefs } from "pinia";
import type {
  Inbox,
  SavedReplyDetail,
  State,
  Article,
  SavedReplyContentType,
  StatsAPIResponse,
  Stats,
  InboxMailComposer,
} from "./types/inbox";
import { useUserStore } from "./user";
import dayjs from "dayjs";
import type { ThreadAttachment } from "./types/thread";
import $apiv3 from "@/apiv3";
import { useRootStore } from "./root";
import useAxiosController from "@/functions/useAxiosController";
import type { AxiosError } from "axios";

const { createReqController, cancelReq } = useAxiosController();

export const useInboxStore = defineStore({
  id: "inbox",
  state: (): State => ({
    inboxes: [],
    accInboxes: [],
    inbox: null,
    unifiedStats: undefined,
    inboxSavedRepliesMap: {},
    inboxArticlesMap: {},
    inboxWhatsappTemplatesMap: {},
    inboxViewsMap: {},
    templateIdWhatsappTemplatesMap: {},
    inboxesFromAddressesMap: {},
    inboxMailComposers: [],
    isInboxUniversal: false,
    inboxAccessMap: {
      chat: false,
      mail: false,
      sms: false,
      whatsapp: false,
      discussion: false,
    },
    progressDetail: null,
    helpcenters: [],
    inboxThreadMovementSettings: {},
    showTicketInUniv: false,
    inboxSendMap: {},
  }),
  getters: {
    inboxesMap() {
      const inboxesMap: Record<string, Inbox> = {};

      this.inboxes.forEach((i) => {
        inboxesMap[i.id] = i;
      });

      return inboxesMap;
    },
  },
  actions: {
    async fetchHelpcenters(): Promise<void> {
      const res = await $api.get("/helpcenters.php");
      const { data, status } = res.data;

      if (status === "error") {
        throw new Error();
      }
      this.helpcenters = data.helpcenters;
    },
    async fetchInboxes(withStats?: boolean): Promise<void> {
      const res = await $api.get("/mailboxes.php", {
        params: {
          s: "false",
          universal: true,
        },
      });

      const { data, status } = res.data;

      if (status === "error") {
        throw new Error();
      }

      const rootStore = useRootStore();

      const { pinnedUniversalInboxes } = storeToRefs(rootStore);

      const oldStatsMap: Record<string, Stats | undefined> = {};

      for (const inbox of this.inboxes) {
        oldStatsMap[inbox.id] = inbox.stats;
      }

      this.inboxes = data.mailboxes
        .filter((inbox: Inbox) => inbox.id)
        .map((inbox) => {
          inbox.stats = oldStatsMap[inbox.id];
          return inbox;
        });
      this.showTicketInUniv = data.unified.showTicketNumber;

      if (withStats) {
        const inboxIds = pinnedUniversalInboxes.value.filter(
          (i) => this.inboxesMap[i]
        );
        if (inboxIds.length > 0) {
          let stats: StatsAPIResponse | undefined;
          try {
            stats = await this.getStats(inboxIds);
          } catch (err) {
            if ((err as AxiosError).code == "ERR_CANCELED") {
              return;
            }
            throw err;
          }
          if (stats) {
            this.unifiedStats = stats.universal;
            for (const inbox of this.inboxes) {
              if (stats.inboxes && stats.inboxes[inbox.id]) {
                inbox.stats = stats.inboxes[inbox.id];
              }
            }
          }
        }
      }
    },
    async fetchAccInboxes() {
      const res = await $api.get(`/getAccountMailboxes.php`);

      const { data, status } = res.data;

      if (status === "error") {
        throw new Error();
      }

      this.accInboxes = data;
    },
    async getStats(inboxIds: string[]): Promise<StatsAPIResponse> {
      const url = "/stats";

      // First cancel the previous request if happening
      cancelReq(url);
      // create new request controller for axios
      const controller = createReqController(url);

      const res = await $apiv3.get(url, {
        params: {
          inboxIds,
        },
        signal: controller.signal,
      });
      return res.data;
    },
    async getStatsByInboxId(
      inboxId: string,
      time?: number | null | undefined
    ): Promise<Stats> {
      const url = `/inboxes/${inboxId}/stats`;
      const currentTime = new Date().getTime();
      const getTagStats = time ? currentTime - time >= 100 * 1000 : true;
      // First cancel the previous request if happening
      cancelReq(url);
      // create new request controller for axios
      const controller = createReqController(url);

      const res = await $apiv3.get(url, {
        signal: controller.signal,
        params: {
          tagStats: getTagStats,
        },
      });

      return res.data;
    },
    async fetchInboxById(
      id: string,
      time?: number | null | undefined
    ): Promise<void> {
      const res = await $api.get(`/mailboxes.php`, {
        params: {
          mailboxID: id,
          s: "false",
        },
      });

      const { data, status } = res.data;

      if (status === "error") {
        throw new Error();
      }

      const oldStats = this.inbox?.stats;
      this.inbox = data.mailbox;
      if (this.inbox) {
        this.inbox.stats = oldStats;
      }
      if (this.inbox) {
        try {
          this.inbox.stats = await this.getStatsByInboxId(id, time);
        } catch (err) {
          if ((err as AxiosError).code == "ERR_CANCELED") {
            return;
          }
          throw err;
        }
      }
    },
    async fetchInboxSavedReplies(inboxId: string): Promise<void> {
      const res = await $api.get(`/list-saved-replies.php`, {
        params: {
          mailboxID: inboxId,
        },
      });

      const { data, status } = res.data;

      if (status === "error") {
        throw new Error();
      }

      this.inboxSavedRepliesMap[inboxId] = data.savedReplies;
    },
    async searchInboxSavedReplies(
      searchStr: string,
      inboxId: string
    ): Promise<number[]> {
      const res = await $api.get(`/savedReplies/search.php`, {
        params: {
          query: searchStr,
          mailboxId: inboxId,
        },
      });

      const { data, status } = res.data;

      if (status === "error") {
        throw new Error();
      }

      return data;
    },
    async fetchInboxSavedReplyById(
      id: string,
      inboxId: string,
      recipientName: string,
      recipientEmail: string,
      contentType?: SavedReplyContentType
    ) {
      const res = await $api.get("/savedReplies/get.php", {
        params: {
          mailboxID: inboxId,
          savedReplyID: id,
          recipientName,
          recipientEmail,
          contentType,
        },
      });

      const { data, status } = res.data;
      if (status === "error") {
        throw new Error();
      }

      const savedReply: SavedReplyDetail = data.savedReply;

      return savedReply;
    },
    async copySavedReplyAttachments(
      attachmentOf: "email" | "whatsapp" | "sms" | "whatsappCloud",
      inboxId: string,
      attachments: ThreadAttachment[],
      /**
       * Required in case of email.
       */
      draftId?: number
    ): Promise<{ [oldId: string]: number }> {
      let url = "/savedReplies/attachments/copyToEmail.php";
      const fileIds = attachments.map((a) => a.id);

      let payload: Record<string, any> = {
        emailId: draftId,
        mailboxId: inboxId,
        fileIds,
      };

      if (
        attachmentOf === "whatsapp" ||
        attachmentOf === "sms" ||
        attachmentOf === "whatsappCloud"
      ) {
        url = `/${attachmentOf}/uploadSavedReplyAttachment.php`;
        payload = attachments;
      }

      const res = await $api.post(url, payload);

      const { data, status } = res.data;
      if (status === "error") {
        throw new Error();
      }

      if (
        attachmentOf === "whatsapp" ||
        attachmentOf === "sms" ||
        attachmentOf === "whatsappCloud"
      ) {
        const files: number[] = data.files;
        const fileIdsMap: { [oldId: string]: number } = {};

        // Creating a { [oldId: string]: number } structure
        attachments.forEach((a, idx) => {
          fileIdsMap[a.id] = files[idx];
        });

        return fileIdsMap;
      }

      return data;
    },

    async fetchInboxArticles(inboxId: string): Promise<void> {
      const res = await $api.get(`/helpcenter/articles/search_V2`, {
        params: {
          mailboxId: inboxId,
        },
      });

      const { data, status } = res.data;

      if (status === "error") {
        throw new Error();
      }

      this.inboxArticlesMap[inboxId] = data;
    },
    async searchInboxArticles(
      searchStr: string,
      inboxId: string
    ): Promise<Article[]> {
      const res = await $api.get(`/helpcenter/articles/search_V2`, {
        params: {
          query: searchStr,
          mailboxId: inboxId,
        },
      });
      const { data, status } = res.data;

      if (status === "error") {
        throw new Error();
      }

      const articles: Article[] = data.map((d: Record<string, any>) => {
        return {
          id: d.id,
          title: d.title,
          description: d.description,
          featured_img: "",
          type: d.type,
          url: d.url,
        };
      });

      return articles;
    },
    async fetchInboxWhatsappTemplates(inboxId: string): Promise<void> {
      const res = await $api.get(`/integration/whatsapp/get_templates`, {
        params: {
          mailboxID: inboxId,
        },
      });

      const { data, status } = res.data;

      if (status === "error") {
        throw new Error();
      }

      this.inboxWhatsappTemplatesMap[inboxId] = data;

      this.inboxWhatsappTemplatesMap[inboxId]?.forEach((i) => {
        this.templateIdWhatsappTemplatesMap[i.id] = i;
      });
    },
    async toggleNewExperience(inboxId: string) {
      const res = await $api.post("/toggleMailboxUI.php", {
        mailboxId: inboxId,
      });

      const { status } = res.data;

      if (status === "error") {
        throw new Error("Couldn't update the experience, please try again");
      }
    },
    async toggleDraftLock(
      draftId: number,
      inboxId: string,
      threadId: number | string,
      userId: string,
      lock: boolean
    ) {
      const userStore = useUserStore();
      const { user } = userStore;

      if (!user) return;

      const dbRef = ref(
        db,
        parentRef +
          `/Mailbox-${inboxId}/UnivThread-${threadId}/lockDraft/${userId}/${draftId}`
      );

      if (lock) {
        set(dbRef, dayjs().unix());
      } else {
        remove(dbRef);
      }
    },
    async fetchInboxImportProgress(inboxId: string) {
      const res = await $api.get("importProgress/get.php", {
        params: {
          mailboxID: inboxId,
        },
      });

      const { data, status } = res.data;
      if (status === "error") {
        throw new Error();
      }

      if (status === "show_progress") {
        this.progressDetail = data;
      }
    },
    async fetchInboxState(inboxId: number): Promise<boolean> {
      if (inboxId !== 204376) {
        const res = await $api.get("inboxes/getInboxState.php", {
          params: {
            inboxId,
            action: "inbox_state",
          },
        });

        if (res.data.data["id"] && res.data.data["isCompleted"] !== 1) {
          return true;
        }
      }

      return false;
    },
    async filterComposer(
      composerId: string | undefined,
      composerData: InboxMailComposer
    ) {
      let { id } = composerData;
      if (composerId) {
        id = composerId;
      }

      this.inboxMailComposers = this.inboxMailComposers.filter(
        (c) => c.id !== id
      );
    },
  },
});
